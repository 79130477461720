<template>
  <div class="auth-white-block">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.auth-white-block {
  background: $white;
  border-radius: $border-radius-lg;
  padding: 32px 40px;
  border: 1px solid $gray2;
  &:deep(.tm-form-line:not(.tm-form-line--left-label)) {
    .tm-form-line__input-wrapper {
      margin-top: 3px;
    }
  }
  @include media-md-max {
    padding: 32px;
  }
}
</style>
